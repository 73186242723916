// import logo from './logo.svg';
import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../node_modules/bootstrap/dist/js/bootstrap.bundle"
// import Navbar from './view_components/Navbar/Navbar';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Home from './view_components/Home/Home';
import Work from './view_components/Work/Work';
import About from './view_components/About/About';
import Contact from './view_components/Contact/Contact';
import Product1 from './view_components/Product/Product1';
import Product2 from './view_components/Product/Product2';
import Product3 from './view_components/Product/Product3';
import Privacy from './view_components/Policy/Privacy/Privacy';
import Term from './view_components/Policy/Term/Term';
import WBSTerm from './view_components/Policy/WBS/WBSTerm';
import WBSPrivacy from './view_components/Policy/WBS/WBSPrivacy';

function App() {
  return (

    <div className='app'>
      <BrowserRouter>
        {/* <Navbar /> */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/Products/MyLuckyDays/Terms_and_Conditions.html" element={<Term />} />
          <Route exact path="/Products/MyLuckyDays/PrivacyPolicy.html" element={<Privacy />} />
          <Route exact path="/Products/MyLuckyDays/Terms_and_Conditions" element={<Term />} />
          <Route exact path="/Products/MyLuckyDays/PrivacyPolicy" element={<Privacy />} />
          {/* WBSPrivacy */}
          <Route exact path="/Legal/WBS/privacy" element={<WBSPrivacy />} />
          <Route exact path="/Legal/WBS/Termsandconditions" element={<WBSTerm />} />
          <Route exact path="/products" element={<Work />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/products/web-wiki-bedtime-stories" element={<Product1 />} />
          <Route exact path="/products/my-lucky-day" element={<Product2 />} />
          <Route exact path="/products/wiki-bedtime-stories" element={<Product3 />} />
          {/* <Route exact path="*" element={<Navigate to="/" />} /> */}
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default App;
