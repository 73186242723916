import React from 'react'
import logo from "../../Asset/Images/Navbar/logo.jpg"
import logoname from "../../Asset/Images/Navbar/logoname.jpg"
import wbsMockup1 from "../../Asset/Images/product/wbsmockup/wbsMockup1.png"
import wbsMockup2 from "../../Asset/Images/product/wbsmockup/wbsMockup2.png"
import wbsMockup3 from "../../Asset/Images/product/wbsmockup/wbsMockup3.png"
import wbsMockup4 from "../../Asset/Images/product/wbsmockup/wbsMockup4.png"
import twitter from "../../Asset/Images/product/twitter.jpg";
import linkedin from "../../Asset/Images/product/linkedin.jpg";
import Instagram from "../../Asset/Images/contact/Instagram.jpg";
import "../Product/Product.css"
import googleplay from "../../Asset/Images/product/googleplay.jpg"
import appstore from "../../Asset/Images/product/appstore.jpg"
import fb from "../../Asset/Images/product/fb.jpg";
import wbs from "../../Asset/Images/product/wbsmockup/wbs.jpg";
import { Link } from "react-router-dom";
import Navbar from '../Navbar/Navbar'
const Product = () => {
    return (
        <div>
            <Navbar />
            <div className="container-fluid mb-6">
                <div className="row">
                    <div className="col-10 mx-auto">
                        <div className="row">
                            <div className="col-md-10 col-10 mx-auto">

                                <div className="product">
                                    <div className="product_1">
                                        <h1 className='product_name'>Wiki Bedtime Stories</h1>
                                    </div>
                                    <div className="product_left">
                                        <img src={wbs} className="img-fluid" alt="1" />
                                    </div>
                                    <div className='product_align'>
                                        <div className="product_middle">
                                            <h2>About the Product</h2>
                                        </div>
                                        {/* <Link className='non-link' to={'//www.wikibedtimestories.com/'} target="_blank">

                                            wikibedtimestories.com
                                        </Link> */}
                                        <div className="product_desc">

                                            {/* <h4>wikibedtimestories.com:</h4> */}
                                            <p>
                                                {/* <Link className='non-link' to={{ pathname: 'https://wikibedtimestories.com/' }} target="_blank">

                                                    wikibedtimestories.com
                                                </Link> */}
                                                <a
                                                    className='non-link'
                                                    href='https://wikibedtimestories.com/'
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                >
                                                    wikibedtimestories.com
                                                </a>
                                                {/* wikibedtimestories.com */}
                                                provides bedtime stories for kids. The aim is to improve the bedtime storytelling experience and quality for at least 1% of global kids from all countries, cultures and background.</p>
                                            <h4>The Motivation behind the Product:</h4>
                                            <p>In response to the nightly demands of our own children, aged 2 to 12, for new stories, we recognized the difficulty of constantly finding fresh tales to share. As a result, we developed the WBS (Worldwide Bedtime Stories) platform, aimed at assisting parents worldwide in easily discovering captivating stories for their children. We firmly believe that by sharing stories of adventure, wisdom, and folklore, we can contribute to making the world a better place.</p>
                                            <p>While the brightest minds in the world are currently focused on addressing long-term challenges such as climate change and Mars colonization, we have chosen to concentrate on solving the important matter of strengthening the bond between parents and children.</p>
                                            <h4>Product Evolution:</h4>
                                            <p>Similar to raising a child, the evolution and potential of each product are unpredictable. At present, our plan is to create a platform that enables anyone to contribute stories for children in any language. We will assume the role of moderators and publishers, ensuring that the stories meet our quality standards before being made available to the audience.</p>
                                            <h4>Success Criteria:</h4>
                                            <p>According to global statistics, there are approximately 2 billion children worldwide. Even if just 5% of these children fall asleep while listening to stories from our platform, we would consider it a significant achievement. Our primary measure of success lies in the positive impact we can make on a substantial number of young listeners.</p>
                                            <p>From an economic standpoint, if the platform can generate sufficient revenue to sustain and expand itself over a period of 3 to 5 years, we would be highly content with the outcomes. Our focus is not solely on financial gains, but rather on creating a sustainable platform that can continue to provide value to parents and children for years to come.</p>
                                        </div>
                                        {/* <div className="product_mock">
                                            <h2>Mockups</h2>
                                        </div>
                                        <div className="mockup">
                                            <img className="img-fluid mockupimg" src={wbsMockup1} alt="" />
                                            <img className="img-fluid mockupimg" src={wbsMockup2} alt="" />
                                            <img className="img-fluid mockupimg" src={wbsMockup3} alt="" />
                                            <img className="img-fluid mockupimg" src={wbsMockup4} alt="" />
                                        </div> */}
                                        {/* <div className="product_download">
                                            <h2>Download it Now</h2>
                                        </div>
                                        <div className="app_download">
                                            <div className="googleplay" >
                                                <Link to="//play.google.com/store/apps/details?id=tech.kushmanda.wikibedtimestories" target="_blank">
                                                    <img className="download_image" src={googleplay} alt="goo" />
                                                </Link>

                                            </div>
                                            <div className="appstore">
                                                <Link to="//apps.apple.com/us/app/wiki-bedtime-stories/id1483269251" target="_blank">
                                                    <img className="download_image" src={appstore} alt="goo" />
                                                </Link>
                                            </div>
                                        </div> */}

                                    </div>
                                    <div className='ppp1'>
                                        <div className="product_reach1">
                                            <h3>Reach Us At</h3>
                                        </div>

                                        <div className="social">
                                            <div className="s">
                                                <Link to={'//twitter.com/wikibedtime'} target="_blank">
                                                    <img className="download_image" src={twitter} alt="goo" />
                                                </Link>
                                            </div>
                                            <div>
                                                <Link to={'//www.instagram.com/wikibedtimestories/'} target="_blank">
                                                    <img className="download_image" src={Instagram} alt="goo" />
                                                </Link>
                                            </div>
                                            {/* <div>
                                                <Link to={'//www.linkedin.com/company/kushmanda-tech/?originalSubdomain=in'} target="_blank">
                                                    <img className="download_image" src={linkedin} alt="goo" />
                                                </Link>
                                            </div> */}
                                            <div>
                                                <Link to={'//www.facebook.com/profile.php?id=100076326081861'} target="_blank">
                                                    <img className="download_image" src={fb} alt="goo" />
                                                </Link>
                                            </div>

                                        </div>


                                    </div>


                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="aboutproduct_left">
                <img className="img-fluid" src={logo} alt="logo" />
                <div className="aboutproduct_logo">
                    <img className="img-fluid" src={logoname} alt="logoname" />
                </div>
            </div>

        </div>
    )
}
export default Product;