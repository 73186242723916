import React from 'react';
import '../Privacy/Privacy.css'

const Term = () => {
    return (
        <div className="terms-and-conditions">
            <h2>Terms and Conditions</h2>
            <p>Our Terms and Conditions were last updated on Dec 29, 2021.</p>

            <p>Please read these terms and conditions carefully before using Our Service. </p>
            <p>The mobile application with the name of My Lucky Days (hereinafter referred to as “App”) is created and operated by Kushmanda Tech LLC (hereinafter referred to as “We, us, Our”).</p>

            <h3>1. Interpretation and Definitions</h3>
            <p><strong>Interpretation</strong></p>
            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

            <h4>Definitions</h4>
            <p>For the purposes of these Terms and Conditions: </p>
            <ul>
                <li><strong>Application:</strong> means the software program provided by the Company downloaded by You on any electronic device, named My Lucky Days.</li>
                <li><strong>Application Store:</strong> means the digital distribution service operated and developed by Google Inc. (Google Play Store) in which the Application has been downloaded.</li>
                <li><strong>Affiliate:</strong> means an entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for the election of directors or other managing authority.</li>
                <li><strong>Country:</strong> refers to India.</li>
                <li><strong>Device:</strong> means any device that can access the Service such as a cell phone or a digital tablet.</li>
                <li><strong>Feedback:</strong> means feedback, innovations, or suggestions sent by You regarding the attributes, performance, or features of our Service.</li>
                <li><strong>Service:</strong> refers to the Predictions of lucky or not lucky days.</li>
                <li><strong>Terms and Conditions:</strong> (also referred to as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.</li>
                <li><strong>You:</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such an individual is accessing or using the Service, as applicable.</li>
            </ul>

            <h3>2. Acknowledgment</h3>
            <p>These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.</p>
            <p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users, and others who access or use the Service.</p>
            <p>By accessing or using the Service, You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions, then You may not access the Service.</p>
            <p></p>

            {/* <h3>Privacy Policy</h3> */}
            <p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your personal information when You use the Application and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.</p>

            <h3>3. Acceptable Usage</h3>
            <p>The Application provides Predictions as services, all the Users are to use the application in a lawful manner. The users are bound to use the Application only for the purpose it is been launched.</p>

            <h3>4. Modifications to the Application</h3>
            <p>The Company reserves the right to modify, suspend or discontinue, temporarily or permanently, the Application or any service to which it connects, with or without notice and without liability to You.</p>

            <h3>5. Updates to the Application</h3>
            <p>The Company may from time to time provide enhancements or improvements to the features/functionality of the Application, which may include patches, bug fixes, updates, upgrades, and other modifications.</p>
            <p>Updates may modify or delete certain features and/or functionalities of the Application. You agree that the Company has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular features and/or functionalities of the Application to You.</p>
            <p>You further agree that all updates or any other modifications will be (i) deemed to constitute an integral part of the Application, and (ii) subject to the terms and conditions of this Agreement.</p>

            <h3>6. Maintenance and Support</h3>
            <p>The Company does not provide any maintenance or support for the download and use of the Application. To the extent that any maintenance or support is required by applicable law, the Company nor the Application Store shall be obligated to furnish any such maintenance or support.</p>

            <h3>7. Third-Party Services</h3>
            <p>The Application may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services.</p>
            <p>You acknowledge and agree that the Company shall not be responsible for any Third-party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. The Company does not assume and shall not have any liability or responsibility to You or any other person or entity for any Third-party Services.</p>
            <p>You must comply with applicable Third parties' Terms of agreement when using the Application. Third-party Services and links thereto are provided solely as a convenience to You, and You access and use them entirely at your own risk and subject to such third parties' Terms and conditions.</p>

            <h3>8. Copyright Policy</h3>
            <p><strong>Intellectual Property Infringement</strong></p>
            <p>We respect the intellectual property rights of others. It is Our policy to respond to any claim that Content posted on the Service infringes a copyright or other intellectual property infringement of any person.</p>
            <p>If You are a copyright owner, or authorized on behalf of one, and You believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Service, You must submit Your notice in writing to the attention of Us via emaillegal@kushmanda.tech and include in Your notice a detailed description of the alleged infringement. </p>
            <p>You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is infringing Your copyright. </p>
            <p>DMCA Notice and DMCA Procedure for Copyright Infringement Claims </p>
            <p>You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing Us with the following information in writing (see 17 U.S.C 512(c)(3) for further detail): </p>
            <ul>
                <li>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest.</li>
                <li>A description of the copyrighted work that You claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work.</li>
                <li>Identification of the URL or other specific location on the Service where the material that You claim is infringing is located.</li>
                <li>Your address, telephone number, and email address.</li>
                <li>A statement by You that You have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.</li>
                <li>A statement by You, made under penalty of perjury, that the above information in Your notice is accurate and that You are the copyright owner or authorized to act on the copyright owner's behalf.</li>


            </ul>
            <p>
                You can contact our copyright agent via email legal@kushmanda.tech. Upon receipt of a notification, the Company will take whatever action, in its sole discretion, it deems appropriate, including removal of the challenged content from the Service.
            </p>
            <h3>9. Intellectual Property </h3>
            <p>The Service and its original content features and functionality are and will remain the exclusive property of the Company and its licensors. </p>
            <p>The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries. </p>
            <p>Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company. </p>
            <h3>10. Liability and Indemnity:</h3>
            <ol type='A'>
                <li>
                    <p>We agrees to indemnify you for the losses incurred, arising out of or in relation to (I) any violation of this Terms by Us or (II) breach of Our Privacy Policy by Us, (III) any liability that cannot be excluded by law. </p>
                    <p>Subject to clause 10 (A) the Company shall not be liable for any indirect, special or consequential losses suffered by You.  </p>
                </li>
                <li>You agree to indemnify and hold the Company and its parents, subsidiaries, affiliates, officers, employees, agents, partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys' fees, due to or arising out of your: (a) use of the Application; (b) violation of this Agreement or any law or regulation; or (c) violation of any right of a third party. </li>

            </ol>
            <h3>11. No Warranties </h3>
            <p> The Application is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Application, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Application will meet your requirements, achieve any intended results, be compatible or work with any other software, applications, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected. </p>
            <p>There is no warranty on the accuracy over the predictions as they are completely generated out of computer programs and algorithms. Thus, the outcome of the Application cannot be considered as expert opinion nor advise. All the predictions or other messages that is given as the outcome of the Application are purely for entertainment purpose only. We cannot be held liable for any predictions of the Application.</p>
            <p>Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Application, or the information, content, and materials or products included thereon; (ii) that the Application will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Application; or (iv) that the Application, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components. </p>
            <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law. To the extent any warranty exists under law that cannot be disclaimed, the Company, not the Application Store, shall be solely responsible for such warranty. </p>
            <h3>12. Termination</h3>
            <p>We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions. </p>
            <p>Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may simply discontinue using the Service. </p>
            <h3>13. Severability</h3>
            <p>If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect. </p>
            <h3>14. Waiver</h3>
            <p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Agreement shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach. </p>
            <h3>15. United States Legal Compliance </h3>
            <p>You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties. </p>
            <h3>16. Changes to this Agreement </h3>
            <p>The laws of India, excluding its conflicts of law rules, shall govern this Agreement and your use of the Application. Your use of the Application may also be subject to other local, state, national, or international laws. The parties agree that the venue for purposes of any and all lawsuits, causes of action, arbitrations or other disputes shall be in Chennai, Tamil Nadu, India. </p>
            <h3>18. Entire Agreement</h3>
            <p>The Agreement constitutes the entire agreement between You and the Company regarding your use of the Application and supersedes all prior and contemporaneous written or oral agreements between You and the Company. </p>
            <h3>19. Contact Us</h3>
            <p>If you have any questions about this Agreement, You can contact Us: :</p>
            <p>If you have any questions about this Agreement, You can contact Us: </p>
            <p>If you have any questions about this Agreement, You can contact Us: </p>

            {/* ... and so on for the rest of the content */}
        </div>
    );
};

export default Term;
